<template>
  <div class="home">
    <Payuformmony />
    </div>
</template>
<script>
import Payuformmony from "../components/Dashboad/Payuformmony.vue";
export default {
  name: "Payuform",
  components: {
      Payuformmony,
      },

      
};
</script>