<template>
  <section class="dash-front">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6 text-center " v-if="hash !== null">
        

          <div class="dash-edit-section pt-4 pb-4">
              <h3 class="card-text">You are just one step away</h3>

          <p>The Total Amount of : INR {{ posted.amount }}</p>
            <form
              action="https://test.payu.in/_payment"
              method="POST"
              id="payuForm"
              name="payuForm"
            >
              <br />
              <div style="display: none">
                <input type="hidden" name="key" :value="posted.key" /><br />
                <input type="hidden" name="hash" :value="hash" /><br />
                <input type="hidden" name="txnid" :value="posted.txnid" /><br />
                <input
                  type="hidden"
                  name="amount"
                  :value="posted.amount"
                /><br />
              
                <input
                  type="hidden"
                  name="email"
                  id="email"
                  :value="posted.email"
                /><br />
                <input
                  type="hidden"
                  name="productinfo"
                  :value="posted.productinfo"
                /><br />
                <input type="hidden" name="surl" :value="posted.surl" /><br />
                <input type="hidden" name="furl" :value="posted.furl" /><br />
                <input
                  type="hidden"
                  name="service_provider"
                  :value="posted.service_provider"
                /><br />
              </div>
              <!-- <input type="submit" value="Submit" /> -->
              <button id="confirmButton" type="submit" class="submit-btn">
                Click to Confirm
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      posted: this.$route.params.posted,
      hash: this.$route.params.hash,
      action: this.$route.params.action,
    };
  },
};
</script>

<style >
.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  height: 320px;
  width: 250px;
  margin: 50px;
  padding: 80px;
  text-align: center;
  border-radius: 10px;
  padding-bottom: 10px;
  background-color: #fff;
  content-align: center;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.submit-btn {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  color: #154c79;
  transition: 0.6s;
  align: center;
  margin: auto;
  margin-bottom: 20px;
  margin-top: 20px;
  border: 2px solid #154c79;
  border-radius: 15px;
  background-color: #fec13c;
  padding: 5px 60px 5px;
}

.submit-btn:hover {
  color: #fff;
  border: 2px solid #154c79;
  background-color: #154c79;
  cursor: pointer;
}

.submit-btn:disabled {
  color: #fff;
  border: 2px solid #363a3d;
  background-color: #363a3d;
  cursor: default;
}
</style>